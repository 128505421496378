<template>
  <div>
    <el-button type="primary" size="mini" @click="createForm">{{$t('button.add')}}</el-button>
    <el-button type="danger" :disabled="disabled" size="mini" @click="removeCategory(deleteCategory)">{{$t('button.del')}}</el-button>

    <el-row>
      <el-col :span="24" class="mt20">
        <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="list"
            @selection-change="categoryChange"
            size="small"
            tooltip-effect="dark">
          <el-table-column
              type="selection">
          </el-table-column>
          <el-table-column
              prop="name"
              label="分类名">
          </el-table-column>
          <el-table-column
              prop="desc"
              label="描述">
          </el-table-column>
          <!--<el-table-column
              label="状态">
            <template v-slot="scope">
              <el-switch
                  v-model="scope.row.status">
              </el-switch>
            </template>
          </el-table-column>-->
          <el-table-column
              prop="username"
              label="创建用户">
          </el-table-column>
          <el-table-column
              prop="create_time"
              label="创建时间"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              prop="update_time"
              label="修改时间"
              show-overflow-tooltip>
          </el-table-column>
          <el-table-column
              width="200"
              label="操作">
            <template v-slot="scope">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="getCategory(scope.row)">{{$t('button.edit')}}</el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeCategory(scope.row.id)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-pagination
        class="mt20"
        :hide-on-single-page="onePage"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        @size-change="categorySizeChange"
        @current-change="categoryCurrentChange"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

    <!-- 添加分类对话框 -->
    <el-dialog
        :title="$t('button.add')"
        :visible.sync="addDialogVisible"
        @close="dialogClose('add')"
        width="50%">
      <create-form
          ref="add"
          size='medium'
          :createData="addDate"
          :createForm="addForm"
          :rule="fromRules">
      </create-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('button.cancel')}}</el-button>
        <el-button type="primary" @click="create">{{$t('button.ok')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'; // 深拷贝
import config from '@/config';
import {getProductApp, createProductApp, updateProductApp, removeProductApp} from '@/api/os/admin';
import CreateForm from '@/components/Form';

export default {
  name: 'console-category',
  data() {
    return {
      list: [],
      activeName: '',
      addDialogVisible: false,
      loading: true,
      pageSize: config.default.pageLimit,        // 分页大小
      pageSizes: config.default.pageSizes,
      currentPage: 1,     // 当前页
      total: 0,
      onePage: false,
      // 添加分类
      addDate:{
        name: '',
        desc: '',
        // status: true
      },
      // 删除数据
      deleteCategory: '',
      disabled: true,
      selected: 0
    }
  },
  components: {
    CreateForm,
  },
  created() {
    this.getList()
  },
  computed: {
    // add
    addForm() {
      return [
        {
          type: 'Input',
          label: this.$t('form.name'),
          prop: 'name',
          placeholder: this.$t('form.name')
        },
        {
          type: 'textarea',
          label: this.$t('form.desc'),
          prop: 'desc',
          row: '5',
          max: 30,
          placeholder: this.$t('form.desc')
        },
        {
          type: 'Switch',
          label: this.$t('form.category.add.status'),
          prop: 'status'
        }
      ]
    },
    // 表单验证
    fromRules() {
      const rules = {
        name: [
          { required: true, message: this.$t('validate.empty'), trigger: 'blur' },
          { min: 2, max: 10, message: this.$t('validate.max_length') + '2~10', trigger: 'blur' }
        ],
      }
      return rules
    }
  },
  methods: {
    getList() {
      getProductApp({}).then(res => {
        let data = res.data.data;
        this.list = data.list;
        this.total = res.data.data.total
        this.loading = false;
        if(this.total <= this.pageSize) {
          this.onePage = true
        }
      })
    },
    getCategory(item) {
      this.addDialogVisible = true
      // 深拷贝对象
      this.addDate = _.cloneDeep(item)
    },
    // 删除选中的id
    removeCategory(id) {
      if(!id) {
        this.$message.error(this.$t('tips.not_selected'))
        return
      }
      this.$confirm(this.$t('tips.del'), this.$t('tip'), {
        type: 'warning'
      }).then(() => {
        removeProductApp(id).then(res => {
          if(res.data.code === 1) {
            this.$message({
              type: 'success',
              message: this.$t('tips.success')
            });
            this.getList()
          }
        })
      })
    },
    // 关闭对话框
    dialogClose(ref) {
      // this.$refs[ref].resetFields()
      this.$nextTick(() => {
        this.$refs[ref].clearValidate()
      })
    },
    createForm() {
      this.addDialogVisible = true;
      this.addDate = {}
    },
    async create() {
      let valid = await this.$refs.add.validate()
      if( !valid ) return;

      if(this.addDate.id) {
        return this.update();
      }
      createProductApp(this.addDate).then(res => {
        this.$message.success(res.data.msg)
        this.addDialogVisible = false
        this.dialogClose('add')
        this.getList()
      })
    },
    // 修改分类信息
    async update() {
      updateProductApp(this.addDate).then(res => {
        if(res.data.code === 1) {
          this.addDialogVisible = false
          this.$message.success(this.$t('tips.success'));
          this.getList()
        }
      })
    },
    // 选中的分类
    categoryChange(selection) {
      let data = []
      selection.forEach((v, i) => {
        data.push(v.id)
      })
      this.selected = data.length
      this.deleteCategory = data.join(',')

      if(this.deleteCategory) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    // 分页
    categorySizeChange(pagesize) {
      this.pageSize = pagesize
      this.getList()
    },
    categoryCurrentChange(page) {
      this.currentPage = page
      this.getList()
    }
  }
}
</script>